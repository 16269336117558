import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this.raf = true;

        window.requestAnimationFrame(this._raf.bind(this));
    }

    disconnect() {
        this.raf = false;
    }

    _raf() {
        this.element.style.top = `${window.scrollY}px`;

        if (this.raf) {
            window.requestAnimationFrame(this._raf.bind(this));
        }
    }
}
