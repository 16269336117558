import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['overlayContainer', 'overlayContent'];

    closeOverlay(event) {
        event.preventDefault();

        if (this.hasOverlayContainerTarget) {
            this.overlayContainerTarget.classList.remove('show');
        }
    }

    loadInOverlay(event) {
        if (!this.hasOverlayContainerTarget || !this.hasOverlayContentTarget) {
            return;
        }

        event.preventDefault();

        const href = new URL(event.currentTarget.href);
        href.searchParams.append('overlay', true);

        this.overlayContentTarget.innerHTML = '';
        this.overlayContainerTarget.classList.add('show');
        this.overlayContainerTarget.classList.add('loading');


        fetch(href)
            .then((response) => {
                return response.text();
            })
            .then((content) => {
                this.overlayContainerTarget.classList.remove('loading');
                this.overlayContentTarget.innerHTML = content;
            })
        ;
    }
}
